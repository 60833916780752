/* About Us */
.aboutus {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    margin-bottom: 10px;
    outline: 1px solid transparent;
    padding-bottom: 55px;

    @media (max-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.aboutus-box {
    background-color: #f2f0f0;
    margin-top: 7%;
    height: auto;
    padding: 10px;
    border-radius: 3px;
    overflow: hidden;
}

.aboutus-box .story {
    text-align: center;
}


.aboutus-box h2 {
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.92px;
    font-size: 1.5em;

    @media (min-width: 1537px) {
        font-size: 2em;
    }

    @media (min-width:2000px) {
        font-size: 2.5em;

    }
}

.aboutus-box p {
    text-align: start;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;

    @media (min-width:1537px) {
        font-size: 1em;
    }

    @media (min-width: 2000px) {
        font-size: 1.3em;

    }
}

.aboutus .grid {
    margin-top: 5%;
}

.aboutus .grid h4 {
    color: #154360;
    margin-bottom: 3%;
}

.aboutus .grid p {
    margin-bottom: 4%;
}

.aboutus .grid .titlee {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.aboutus .grid .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.aboutus .grid .grid-item img {
    width: 180px;
    height: 180px;
    filter: drop-shadow(0 0 10px #3c3c3c);
}

.aboutus .grid .grid-item .about-yourself {
    background: #f2f0f0;
    margin-top: 12px;
    margin-bottom: 30px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    text-align: justify;
    justify-content: flex-start;
    color: #154360;
    box-shadow: var(--box-shadow);

}

.aboutus .about-yourself p {
    font-size: 12px;
    line-height: 1.5;
    margin: 0;

    @media (min-width: 1537px) {
        font-size: 0.8em;
    }

    @media (min-width:2000px) {
        font-size: 1em;

    }
}

.aboutus .grid .grid-item svg {
    height: 30px;
    margin-top: 140px;
    filter: drop-shadow(0 0 20px #3d3d3d);

}