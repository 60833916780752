.cont-responsive-table {
  width: 100%;
  overflow-x: auto; /* Allows horizontal scrolling if the table width exceeds the screen width */
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  background: none; /* Removes any background color for transparency */
}

.table-header {
  font-size: 14px;
  text-align: left;
  display: flex;
  border-bottom: 2px solid black;
}

.col-1, .col-2, .col-3, .col-4 {
  flex: 1; /* Adjusts the flex basis to ensure even distribution */
  padding: 15px;
  display: flex;
  align-items: center;
  border-right: 1px solid #eee;
  box-sizing: border-box;
}

.col-4 {
  justify-content: space-between;
}

.col-3 .col-3-btn {
  background-color: #32A3E9; /* Specific shade of blue */
  color: white;
  border-radius: 20px;
  padding: 8px 15px; /* Increased padding for a larger button */
  text-align: center;
  display: inline-block;
  cursor: pointer; /* Adds the pointer cursor on hover */
  border: none; /* Removes border */
  font-size: 13px; /* Slightly larger font size for better readability */
}

@media screen and (max-width: 760px) {
  .table-header {
    display: none; /* Hides table headers on mobile to use space efficiently */
  }

  li {
    display: block;
    padding: 15px;
    border-bottom: 1px solid #ccc;
  }

  .col {
    display: block;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #eee; /* Provides a light separation between items */
    text-align: left;
    position: relative; /* Necessary for positioning the labels correctly */
    font-size: 13px; /* Smaller font size for better readability on mobile */
  }

  .col:before {
    content: attr(data-label); /* Uses the data-label attribute to display labels */
    display: block;
    font-size: 10px; /* Even smaller font size for labels to not dominate the content */
    color: grey;
    position: absolute;
    top: -20px; /* Positions the label above the text for clear distinction */
    margin-top: 15px;
    padding-bottom: 10px;
  }

  .col-4 {
    justify-content: center;
    padding-top: 10px; /* Adds space above the action buttons for better touch target spacing */
    border-bottom: none; /* Removes the bottom border for the last item */
  }
}
