.main-faq-section{
    background-color: whitesmoke;
    padding-bottom: 50px;
    display: flex;
    min-height: 100vh;
    justify-content: center;
}

.blur-layer {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
  }
  
  /* Ensures text and interactive elements under the blur are not clickable */
  .blur-layer * {
    pointer-events: none;
  }
  

.content-faq-section{
    max-width: 1400px;

  @media (max-width: 1850px){
    width: 1250px;
  }

    @media (max-width: 1280px){
        margin-left: 0px;
        width: 90%;
    }
}

.faq-title {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    margin-bottom: 10px;
    color:#154360;
    margin-bottom: 50px;
}

.last-text{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-inline: 5px;
    margin-top: 30px;
    
    @media (max-width: 667px){
        margin-left: 20px;
    }
}

.last-contact{
    color: #154360;
    font-style: italic;
    cursor: pointer;
}


/* -------------------- Tabs Component ---------------------- */

.extra-space{
    width: 100px;

    @media (max-width: 1850px){
        width: 60px;
    }

    @media (max-width: 1280px){
        display: none;
    }
}

.main-tab-container{
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid #a6abad; */
    border-radius: 10px;
    padding-bottom: 25px;
    column-gap: 104px;
    /* padding-inline: 25px; */
    /* background-color: aqua; */

    @media (max-width: 1850px){
        column-gap: 77px;
    }    

    @media (max-width: 1280px){
        justify-content: space-around;
    }

    @media (max-width: 500px){
        justify-content: center;
    }
}

.tab-btn{
    width: 220px;
    font-size: 1.3rem;
    border-radius: 15px;
    padding-block: 15px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    /* margin-right: 100px; */
    margin-top: 25px;
    color: white;

    @media (max-width: 1280px){
        margin-right: 0px;
    }
}

.simple-tab-btn{
    background-color: #32A3E9;
}

.selected-tab-btn{
    background-color: #154360;
}

/* ---------------- Main Content Component-------------------- */

.questions-main-container{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding-block: 25px;
    /* border: 1px solid #a6abad;  */
    border-radius: 10px;
}

.main-box{
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    padding-block: 15px;
    padding-inline: 15px;
    /* margin-left: 70px; */

    @media (max-width: 667px){
        /* width: 100%; */
        margin-left: 0px;
    }
}

.question-main-box{
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.arrow-icon{
    width: 20px;
    cursor: pointer;
}

.question{
    color: #154360;
    font-weight: bold;
    font-size: 27px;
}

.open-question-box{
    box-shadow: 0 5px 10px 0 #2F4AA8;
}

.answer-box{
    display: flex;
    gap: 10px;
}

.answer{
    font-size: 18px;
    text-align: justify;

}

.tick-container{
    display: flex;
    justify-content: center;
    align-items: center;
}