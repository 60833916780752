.publication-section{
    margin-top: -10px;
}
.publication .back-svg{
    width: 20px;
    margin-top: 10px;
    margin-left: 6.2%;
    z-index: 1;

    @media (min-width:2000px) {
        margin-left: 5.6%;
        
    }
}
.publication{
    background: #def2fd;
    width: 100%;
    padding: 1%;
    margin-top: -20px;
    @media (max-width: 768px) {
        margin-top: 10px;
        
    }
}
.publication .text{
    padding: 1%;
    text-align: left;
    margin-left: 4%;
    font-size: 13px;
    margin-top: 10px;

    @media (max-width: 768px) {
        margin-top: 2.5%;
        font-size: 16px;
    }

    @media (min-width:2600px) {
        font-size: 20px;
    }
    @media (min-width: 1300px) {
        font-size: 16px;        
    }
    @media (min-width: 1500px) {
        font-size: 16px;        
    }
    @media (min-width:2300px) {
        font-size: 20px;
    }
}
.publication .greater-svg{
    width: 1.2%;
    margin-top: 10px;
    @media (max-width: 768px) {
        width: 3%;
        margin-top: 2.2%;
        
    }
}
.publication .title{
    font-size: 25px;
    margin-right: -5%;

    @media (max-width: 768px) {
        padding: 20px;
    }

    @media (min-width:2600px) {
        font-size: 45px;
    }
    @media (min-width: 1300px) {
        font-size: 23px;        
    }
    @media (min-width: 1500px) {
        font-size: 28px;        
    }
    @media (min-width: 1400px) {
        font-size: 28px;        
    }
    @media (min-width: 1900px) {
        font-size: 35px;        
    }
    @media (min-width:2300px) {
        font-size: 40px;
    }
}
.publication hr{
    margin-top: 0.9%;
    border: 1px solid #0078d4;

    @media (max-width: 768px) {
        margin-top: 2.5%;
        
    }
}
.publication .author{
    margin-top: 1%;
    text-align: left;
    margin-left: 5%;
    font-size: 15px;
    @media (max-width: 768px) {
        margin-top: 2.5%;
    }
    @media (min-width:2600px) {
        font-size: 25px;
    }
    @media (min-width: 1300px) {
        font-size: 15px;        
    }
    @media (min-width: 1500px) {
        font-size: 15px;        
    }
    @media (min-width:2300px) {
        font-size: 20px;
    }
    
}
.publication .text2{
    text-align: left;
    margin-left: 5%;
    font-size: 15px;
    margin-top: 0.5%;
    @media (max-width: 768px) {
        margin-top: 2.5%;
    }
    @media (min-width:2600px) {
        font-size: 25px;
    }
    @media (min-width:2300px) {
        font-size: 20px;
    }
    @media (min-width: 1300px) {
        font-size: 15px;        
    }
    @media (min-width: 1500px) {
        font-size: 15px;        
    }

}
.publication button{
    text-align: center;
    display: block;
    margin-left: 4.7%;
    width: 12%;
    height: 25%;
    font-size: small;

    @media (max-width: 768px) {
        display: block;
        width: 21%;
        font-size: 15px;
    }
    @media (min-width:2600px) {
        width: 6%;
    }
    @media (min-width:2500px) {
        width: 4%;
    }

    @media (min-width:2300px) {
        width:7%;
        font-size: 20px;
        
   }
   @media (min-width:1750px) {
    width: 20%; 
   }
    @media (min-width: 1300px) {
        width:10%;       
    }
    @media (min-width: 1800px) {
        width: 17%;      
    }
    @media (min-width: 1400px) {
        width: 10%;      
    }
    @media (min-width: 2000px) {
        width: 8%;      
    }

}
.abstract{
    width: 80%;
    display: flex;
    gap: 15%;
    margin: 50px auto;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        width: 90%;
        margin: 0px auto;
    }

}
.abstract p{
    font-size: 1.4rem;

    @media (max-width: 768px) {
        margin-top: 4%;
        font-size: 1.8rem;
    }
    @media (min-width:2600px) {
        font-size: 45px;
    }
    @media (min-width: 1300px) {
        font-size: 23px;        
    }
    @media (min-width: 1500px) {
        font-size: 28px;        
    }
    @media (min-width: 1900px) {
        font-size: 35px;        
    }
}
.abstract span{
    text-align: justify;
    font-size: 1.4rem;

    @media (min-width:2600px) {
        font-size: 40px;
    }
    @media (min-width: 1300px) {
        font-size: 23px;        
    }
    @media (min-width: 1500px) {
        font-size: 28px;        
    }
    @media (min-width: 1900px) {
        font-size: 35px;        
    }
}
.research-areas{
    display: flex;
    width: 80%;
    margin: 0px auto;
    padding-top: 10%;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 90%;
        margin: 0px auto;
    }
    @media (min-width:1900px) {
        margin-top: -50px;
        
    }
}
.research-areas p{
    font-size: 1.4rem;

    @media (min-width:2600px) {
        font-size: 45px;
    }
    @media (min-width: 1300px) {
        font-size: 23px;        
    }
    @media (min-width: 1500px) {
        font-size: 28px;        
    }
    @media (min-width: 1900px) {
        font-size: 35px;        
    }
}
.two-boxes{
    width: 75%;
    justify-content: space-evenly;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    
}
.two-boxes button{
    height: 50px; 
    width: 300px; 
    font-size: 20px; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border: none;
    border-radius: 18px;

}

.btn-container{
    display: flex;
    justify-content: start;
    padding-left: 4.3%;

    @media (max-width: 768px) {
        justify-content: center;
        padding-left: 0%;
    }
}
