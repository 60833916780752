.password-validation-box {
    padding: 12px;
    margin-left: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    width: auto; 
    min-width: 300px;  
    max-width: auto;  
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 105%; 
    z-index: 10;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
    .password-validation-box {
        left: 50%;  
        transform: translate(-50%, 0); 
        top: 120%;  
        width: 80%;  
        max-width: none;  
    }
}

.password-requirement {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 5px 0;
    text-align: left;
    justify-content: flex-start;
}

.password-requirement i {
    margin-right: 8px; 
}

.valid {
    color: green; 
}

.invalid {
    color: red;  
}

.password-strength-meter {
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 3px;  
}

.progress-bar {
    height: 8px;
    transition: width 0.3s ease;  
}

.password-strength-text {
    font-size: 12px;
    margin-top: 3px;
    font-weight: bold;
}

/* Enhance readability for strength text on small screens */
@media (max-width: 600px) {
    .password-strength-text {
        font-size: 14px;  
    }
}
