.main-container{
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
    min-height: 100vh;
}
.heading{
    color:#154360;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 60px;
    margin-top: 40px;
}

.contact-header{
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: left;
    margin-bottom: 20px;
}

.content-container{
    box-shadow: 0 5px 10px 0 #2F4AA8;
    width: 565px;
    padding: 25px 25px 75px 25px;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: 637px) {
        width: 95%;
    }
}
.heading2{
    color: #154360;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
.para{
    color: #154360;
    font-size: 18px;
    font-weight: bold;
}
.para2{
    color: rgba(84, 82, 82, 0.446);
    font-size: 18px;
    font-weight: bold;
}
.input-container{
    margin-bottom: 20px;

}
.input{
    color: rgba(156, 141, 141, 0.446);
    width: 92%;
    padding: 15px;
    border-radius: 8px;
    border: 2px solid transparent;
}
.textarea{
    color: rgba(156, 141, 141, 0.446);
    width: 97%;
}
.button{
    background-color:#32A3E9;
    color: white;
    padding-block: 15px;
    padding-inline: 80px;
    border-radius: 50px;
    border: 2px solid transparent;
    cursor: pointer;
}

.button:hover{
    background-color: rgb(95, 169, 212);
}

.form-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
}


/* Selector Component CSS */

.input-select-component{
    display: flex;
    width: 100%;
    padding-block: 15px;
    padding-inline: 10px;
    font-size: 20px;
    background-color: rgb(237, 233, 233);
    border: none;
    border-radius: 10px;
    color: #979797;
  }
  
  .input-select-component:focus{
    outline: none;
    border: 1px rgb(95, 169, 212) solid;
  }
  
  /* End Selector Component CSS */
  
  /* TextArea Component CSS */
  
  .input-text-area{
    display: flex;
    width: 100%;
    padding-block: 15px;
    padding-inline: 15px;
    font-size: 20px;
    background-color: rgb(237, 233, 233);
    border: none;
    resize: none;
    border-radius: 10px;
    color: #979797;
  }
  
  .input-text-area:focus{
    outline: none;
    border: 1px rgb(95, 169, 212) solid;
  }