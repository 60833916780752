.consistent-margins {
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: 1px solid transparent;
  padding-bottom: 55px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
#blog-container {
  flex-direction: column;
  margin-top: 0;
}
#blog-container h1 {
  font-size: 46px;
  margin-top: 70px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: underline;
}

#blog-container span {
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
}
#blog-container .date {
  margin-bottom: 20px;
}

#blog-container h2 {
  font-size: 24px;
  font-weight: bold;
  color: #2f4aa8;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
#blog-container h3 {
  font-size: 22px;
  font-weight: bold;
  color: #2f4aa8;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
}
#blog-container p {
  line-height: 1.3;
  color: #333;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  text-align: justify;
}
#blog-container img {
  max-width: 50%;
  height: 60%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
}
.subheading {
  font-size: 18px;
  font-weight: bold;
  color: #2f4aa8;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}
#blog-container ul {
  text-align: start;
  list-style: none;
  padding-left: 20px;
  margin-bottom: 10px;
}
#blog-container li {
  margin-bottom: 5px;
  position: relative;
}
#blog-container .hashtags {
  display: flex;
  gap: 5px;
  margin-bottom: 30px;
}

#blog-container .circle-bullet {
  list-style-type: disc;
  margin-bottom: 5px;
  position: relative;
}

#blog-container .circle-subbullet {
  list-style-type: circle;
  margin-bottom: 5px;
  position: relative;
}