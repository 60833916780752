.alert-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6); /* Dark transparent background */
    z-index: 1000;
  }
  
  .alert-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .alert-heading {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 24px;
    color: #e74c3c; /* Red for the alert heading */
  }
  
  .alert-paragraph {
    font-size: 18px;
    color: #333;
  }
  
  .alert-button {
    background-color: #e74c3c; /* Red button */
    color: #fff;
    border: none;
    padding: 6px 10px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
  }
  
  .alert-button:hover {
    background-color: #c0392b; /* Darker red on hover */
  }
  