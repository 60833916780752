.main-api-section {
  margin-top: 3%;
  display: flex;
  align-items: start;
  width: 100%;
  height: 100%;
}
.left-api-side-panel {
  width: 10%;
  margin-top: 1%;
  height: 100%;

  @media (min-width: 768px) {
    width: 22%;
  }
  @media (min-width: 900px) {
    width: 25%;
  }
  @media (min-width: 2000px) {
    width: 15%;
  }
  @media (min-width: 2500px) {
    width: 17%;
  }
}
.left-api-side-panel-mobile .api-side-searchbar input{
  margin-bottom: 2%;
  margin-top: 6%;
  margin-left: 11%;
  width: 80%;
}
.left-api-side-panel-mobile .api-side-searchbar .glass-icon{
  margin-left: -12%;
  margin-bottom: -1%;
}
.left-api-side-panel .pages {
  color: gray;
  margin-top: -2%;
  margin-bottom: 4%;
  font-size: 14px;
  font-weight: bold;


  @media (min-width: 900px) {
    font-size: 18px;
  }
  @media (min-width: 1700px) {
    font-size: 20px;
  }
  @media (min-width: 2000px) {
    font-size: 20px;
  }
  @media (min-width: 2500px) {
    font-size: 20px;
  }
}
.left-api-side-panel .side-title {
  line-height: normal;
  margin-bottom: 5%;
  font-size: 16px;
  text-align: left;
  margin-left: 9%;

  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 900px) {
    font-size: 16px;
  } @media (min-width: 1700px) {
    font-size: 22px;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
}
.left-api-side-panel .side-title:hover {
  background-color: hsl(204, 75%, 84%);
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 6%;
}
.left-api-side-panel .side-title.selected {
  background-color: hsl(204, 75%, 84%);
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 6%;
}
.api-side-searchbar {
  margin-bottom: 11%;
}
.api-side-searchbar input {
  background-color: hsl(204, 75%, 84%);
  margin-top: -7%;
  margin-right: 3%;


  @media (min-width: 768px) {
    width: 85%;
  }
  @media (min-width: 1700px) {
    width: 75%;
  }
  @media (min-width: 2000px) {
    width: 80%;
  }
  @media (min-width: 2500px) {
    width: 80%;
  }
 
}
 .api-side-searchbar .glass-icon {
  margin-bottom: -2%;
  margin-left: -13%;

  @media (max-width: 800px) {
    margin-left: -20%;
  }
  @media (min-width: 1200px) {
    margin-bottom: -1%;
  }
  @media (min-width: 1700px) {
    margin-bottom: -2%;
  }
  @media (min-width: 2000px) {
    margin-bottom: -1%;
  }
  @media (min-width: 2500px) {
    margin-bottom: -1%;
  }
 
}
 .api-side-searchbar .glass-icon:hover {
  fill: blue;
}
.api-v-line {
  border-left: 1px solid black;
  margin-top: 1%;

  @media (min-width:200px) {
    height: 2400px; 
  }
  @media (min-width:1200px) {
    height: 2600px;
  }
  @media (min-width:2500px) {
    height: 3000px;
  }
}
.h-line {
  margin-left: 1%;
}

.api-middle-content {
  width: 80%;
  text-align: left;
  margin-left: 0.3%;

  @media (min-width:200px) and (max-width:530px) {
    width: 100%;
    margin-left: -19%;
  }
  @media (min-width:540px) and (max-width:650px) {
    width: 100%;
    margin-left: -19%;
    
  }
  @media (min-width:532px) and (max-width: 767px) {
    width: 100%;
    margin-left: -13%;
  }
}
 .api-middle-content .title {
  text-align: left;
  font-size: 35px;
  margin-left: 2%;
  margin-bottom: 1%;
  text-decoration: underline;

 
  @media (min-width: 1700px) {
    font-size: 38px;
  }
  @media (min-width: 2000px) {
    font-size: 38px;
  }
  @media (min-width: 2500px) {
    font-size: 40px;
  }
  
}
 .api-two-sections {
  display: flex;
  width: 100%;
}
.api-two-sections .api-left-div{
  width: 85%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.api-two-sections .api-left-div p{
  margin-bottom: 5%;
}
.api-two-sections .api-right-div{
  width: 15%;
  padding: 1rem;
}
.first-endpoint-box-text{
  display: flex;
  justify-content: space-between;
}
.first-endpoint-box-text .endpoint-box{
  margin-right:4%;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
}
.first-endpoint-box-text p{
  margin-right: 5%;
  text-align: justify;
}
.endpoint-box .box-header{
  padding: 5px;
  background-color: #3ca6e7;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.endpoint-box .box-content{
  background-color: #cae5f5;
  padding: 20px;
  font-size: medium;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.api-left-div .api-box{
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  margin: 3%;
  background: #686666; 
  color: #f8f8f2; 
  border-radius: 4px; 
  margin: 1em 0; 
  overflow: hidden; 
}
.api-left-div .api-box .box-content{
  padding: 1em;
white-space: pre;
}
.box-header .header-right-item{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4%;
}
.box-header svg{
  width: 8%;
  padding: 3px;
  cursor: pointer;
}

.api-box .box-header{
background: #464545; 
color: #ffffff; 
padding: 0.4em 1em; 
display: flex;
justify-content: space-between;
align-items: center;

}
.api-table-of-contents{
  margin-left: 3%;
}
.api-table-of-contents h2{
  text-align: start;
}
.api-table-of-contents a{
  font-size: 14px;
}
.api-table-of-contents .sub-heading{
  margin-left: 10%;
}

.api-two-sections .selected-title{
  background-color: hsl(204, 75%, 84%);
  margin-right: 6%;
  padding: 5px 5px;
  border-radius: 5px;
}

.api-two-sections .dropdown{
  width: 120px;
  padding: 5px;
  border-radius: 5px;
}

.api-two-sections .headers-container{
  background-color: bisque;
  padding: 0px 20px;
}

.api-two-sections .header-cont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.api-two-sections  .header-name{
  width: 30%;
  font-size: medium;
  font-weight: bold;
}

.api-two-sections .header-value{
  width: 70%;
}

.api-two-sections .post-message{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
  padding: 10px;
}

.api-two-sections  .send-request-btn{
  background-color:aqua;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-left: 30px;
  cursor: pointer;
  width: 150px;
}

.api-two-sections .send-request-btn:hover{
  background-color: rgb(0, 255, 255);
  font-weight: bold;
}
.api-main-container .btn-expand, .btn-collapse{
  display: inline-block;
  background: #32a3e9;
  color: white;
  border: none;
  padding: 15px 25px;
  margin: 10px;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: inherit;
  width: 12%;
}
.btn-collapse{
  background-color: gray;
}
.btn-expand svg, .btn-collapse svg{
  width: 30px;
  margin-top: -3%;
  margin-left: 3px;
}
.expand-content{
  display: flex;
  align-items: center;
}
.collapse-content{
  display: flex;
  align-items: center;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.staticcode{
display: flex;
align-items: center;
justify-content: center;
}
.staticcode .static-code-left-side{
width: 50%;
}
.staticcode .static-code-right-side{
width: 50%;
}



.api-box-response {
background: #F5F5F5; 
color: #333; 
border: 1px solid #DDD; 
border-radius: 4px; 
margin: 1em 0; 
overflow: hidden; 
}

.api-box-response .box-header {
background: #E0E0E0; 
color: #333; 
padding: 0.4em 1em; 
display: flex;
justify-content: space-between;
align-items: center;
}

.api-box-response .box-content {
padding: 1em;
background: #f4f2f2; 
overflow-y: auto; 
overflow-x: hidden; 
height: auto; 
max-height: 200px; 
}

.api-box .box-content,
.api-box-response .box-content {
overflow-y: auto; 
overflow-x: auto; 
max-height: 300px; 
white-space: pre-wrap; 
}
#staticcode .box-content{
overflow-y: auto; 
overflow-x: auto; 
max-height: 150px; 
white-space: pre-wrap;
}
#error-types .box-content{
height: auto;
}


.api-box .box-content::-webkit-scrollbar,
.api-box-response .box-content::-webkit-scrollbar {
width: 8px; 
height: 8px; 
}

.api-box .box-content::-webkit-scrollbar-track,
.api-box-response .box-content::-webkit-scrollbar-track {
background: #f0f0f0; 
}

.api-box .box-content::-webkit-scrollbar-thumb,
.api-box-response .box-content::-webkit-scrollbar-thumb {
background: #c1c1c1; 
border-radius: 4px; 
}

.api-box .box-content::-webkit-scrollbar-thumb:hover,
.api-box-response .box-content::-webkit-scrollbar-thumb:hover {
background: #a8a8a8; 
}

.copy-icon {
cursor: pointer;
fill: white; 
width: 24px; 
height: 24px; 
}
.api-box-response .copy-icon{
fill: black;
}

.copy-icon:hover {
fill: #1d8eca; 
}



/* --------------------------------------------------------- */
.api-main-container{
display: flex;
justify-content: space-between;
min-height: 100vh;
margin-top: -20px;
}

/* --------------------------------------------------------- */
/* --------------------- LEFT CONTAINER (titles) ------------ */
/* --------------------------------------------------------- */

.module-container{
height: 100%;
width: 20%;
/* background-color: aqua; */
padding-inline: 20px;
padding-top: 30px;
display: flex;
flex-direction: column;
justify-content: flex-start;
gap: 15px;

@media (max-width:764px){
  display: none;
}
}

.controller-heading{
height: 40px;
display: flex;
justify-content: center;
align-items: center;
}

.controller-heading span{
font-size: 22px;
font-weight: bold;
}

.search-title-container{
display: flex;
flex-direction: column;
gap: 20px;
}

.search-container{
background-color: hsl(204, 75%, 84%);
border-radius: 8px;
box-sizing: border-box;
position: relative;
}

.search-container input{
background-color: hsl(204, 75%, 84%);
border: none;
outline: none;
width: 100%;
}

.search-container .search-icon-container{
position: absolute;
right: 0px;
top: 0px;
height: 100%;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
padding-right: 20px;
}

.search-container .search-icon-container svg{
cursor: pointer;
}

.title-container{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
}

.title-container p{
font-size: 14px;
font-weight: 600;
cursor: pointer;
width: 100%;
text-align: left;
padding-block: 5px;
padding-inline: 10px;
}

.selected-title{
color: hsl(205, 82%, 59%);
font-weight: 900;
}

/* --------------------------------------------------------- */
/* ---------------- Right CONTAINER (endpoints) --------------- */
/* --------------------------------------------------------- */

.main-content-container{
padding-top: 30px;
width: 80%;
border-left: #625a5a solid 1px;
display: flex;
flex-direction: column;
gap: 10px;


padding-inline: 30px;
min-height: 100vh;

@media (max-width:764px){
  width: 100%;
  border-left: none;
  padding-inline: 20px;
}

}

.heading-cont{
display: flex;
justify-content: flex-start;
}

.heading-cont h1{
font-size: 24px;
font-weight: bold;
margin-right: 20px;
}

.endpoint-container{
width: 100%;
min-height: 90%;
display: flex;
justify-content: space-between;
margin-top: 10px;
position: relative;
}


.endpoint-data-container{
width: 85%;
min-height: 100%;
position: relative;
top: 0;
overflow: auto;
scrollbar-width: thin;
padding-right: 5px;

@media (max-width:764px){
  width: 100%;
  margin-right: 0px;
}
}

.endpoint-list-container{
  position: relative;
width: 15%;
display: flex;
flex-direction: column;
align-items: flex-start;
padding-left: 20px;
@media (max-width:764px){
  display: none;
}
}

.endpoint-list-container h2{
margin-bottom: 10px;
}

.endpoint-list-container ul{
display: flex;
flex-direction: column;
align-items: flex-start;
}

.endpoint-list-container a{
font-size: 14px;
}

.selected-endpoint{
font-weight: 600;
color: hsl(205, 82%, 59%);
}

.first-container{
display: flex;
gap: 4%;
margin-bottom: 25px;
/* background-color: #1d8eca; */

@media (max-width:1100px){
  flex-direction: column;
  gap: 10px;
}
}

.description-container{
width: 46%;
height: auto;
/* background-color: aqua; */

@media (max-width:1100px){
  width: 100%;
}
}

.description-container h1 {
font-size: 24px;
font-weight: bold;
margin-bottom: 10px;
text-align: left;
padding-bottom: 5px;
}

.description-container p{
font-size: 14px;
text-align: left;
margin-bottom: 15px;
}

.url-container{
width: 50%;
height: auto;
/* background-color: aqua; */
box-sizing: border-box;

@media (max-width:1100px){
  width: 100%;
}
}

.complete-url-container{
display: flex;
flex-direction: column;
background-color:#f4f2f2;
border-radius: 8px;
border: #E0E0E0 solid 2px;
margin-top: 40px;
@media (max-width:1100px){
  margin-top: 0px;  
}
}

.complete-url-container-code{
display: flex;
flex-direction: column;
background-color:#f4f2f2;
border-radius: 8px;
border: #1b1a1a solid 2px;
margin-top: 40px;
@media (max-width:1100px){
  margin-top: 0px;  
}
}

.endpoint-header{
background-color: #E0E0E0;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
text-align: left;
padding-inline: 10px;
padding-block: 10px;
display: flex;
justify-content: space-between;
align-items: center;
}

.endpoint-header-code{
background-color: #1b1a1a;
color: white;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
text-align: left;
padding-inline: 10px;
padding-block: 10px;
display: flex;
justify-content: space-between;
align-items: center;
}

.endpoint-header span{
font-size: 16px;
font-weight: bold;
}

.endpoint-content pre{
font-size: 13px;
text-align: left;
}

.endpoint-error-types{
padding: 15px;
text-align: left;
color: rgb(104, 100, 100);
}

.main-expend-btn{
  position: absolute;
display: flex;
justify-content: center;
align-items: center;
margin-top: 50px;
z-index: 100;
left: 45%;
}

.expend-btn{
background-color: #32a3e9;
color: white;
border: none;
padding: 10px 25px;
border-radius: 50px;
cursor: pointer;
font-size: 16px;
display: flex;
justify-content: center;
align-items: center;
}

.collapse-btn{
background-color: gray;
color: white;
border: none;
padding: 10px 25px;
border-radius: 50px;
cursor: pointer;
font-size: 16px;
display: flex;
justify-content: center;
align-items: center;
}

.expend-btn svg{
width: 20px;
height: 20px;
}

.collapse-btn svg{
width: 20px;
height: 20px;
}

.subheading-container hr {
margin-bottom: 25px;
}

.languages-container{
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
}

.languages-container .dropdown-container{
display: flex;
justify-content: center;
align-items: center;
}

.languages-container .dropdown-container .dropdown{
width: 120px;
padding: 5px;
border-radius: 5px;
}

.responsive-container{
display: none;
height: 40px;
  
@media (max-width:1100px){
  display: block;
}
}

.btn-main-container{
display: none;
justify-content: space-between;
background-color: #f4f2f2;
margin-bottom: 20px;

@media (max-width:764px){
  display: flex;
}
}

.btn-main-container .menu-btn{
border: none;
padding: 10px 25px;
border-radius: 50px;
cursor: pointer;
font-size: 16px;
display: flex;
justify-content: center;
align-items: center; 
gap: 10px;
flex-grow: 1;
}
.btn-main-container .menu-btn:first-child {
  justify-content: flex-start; /* Ensures the controller button is on the left */
}

.btn-main-container .menu-btn:last-child {
  justify-content: flex-end; /* Pushes the Endpoint button to the far right */
}

.btn-main-container .menu-btn p {
font-size: 16px;
font-weight: bold;
}

.btn-main-container .menu-btn svg{
width: 20px;
height: 20px;
}

.mobile-view-container{
display: none;
flex-direction: column;

@media (max-width:764px){
  display: flex;
}
}

.title-container .title-sub-list{
/* background-color: #32a3e9; */
text-align: left;
margin-left: 25px;
margin-top: -3px;
display: flex;
flex-direction: column;
gap: 5px;
}

.title-container .title-sub-list li{
font-size: 14px;
font-weight: 600;
cursor: pointer;
width: 100%;
text-align: left;
}

.title-container .title-sub-list a{
text-decoration: none;
color: black;
}

.mobile-controllers-main-cont{
background-color: #1d8eca;
position: fixed;
top: 122px;
left: 0px;
width: 100%;
height: 88%;
z-index: 100;
overflow: hidden;
}

.api-overlay {
position: fixed;
inset: 0;
display: flex;
justify-content: center;
align-items: flex-end;
z-index: 50;
}

.api-overlay-background {
position: fixed;
inset: 0;
background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.api-overlay-content {
position: absolute;
background-color: white;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
width: 100%;
height: 92%;
display: flex;
flex-direction: column;
padding-bottom: 6px;
padding-top: 2px;
gap: 30px;
padding-inline: 20px;
padding-top: 30px;
}

.controller-mobile-heading{
height: 40px;
display: flex;
justify-content: space-between;
align-items: center;
}

.controller-mobile-heading span{
font-size: 22px;
font-weight: bold;
}

.controller-mobile-heading svg{
width: 30px;
height: 30px;
cursor: pointer;
}

.mobile-endpoints{
text-align: left;
font-size: 14px;
}

.mobile-endpoints ul{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
}

.pre-design{
padding: 25px;
max-height: 400px;
overflow: auto;
}

.endpoint-main-data-container {
  width: 100%;
  position: sticky;
  overflow: auto;
  scrollbar-width: thin;
  padding-right: 30px;
}
