.parent-blog {
    margin-top: -20px;
    margin-bottom: -50px;
}

.blog-main-container {
    background: linear-gradient(#90c7ea, transparent);
    padding: 0px 7%;
}

.cryptography {
    color: rgb(94, 15, 167);
    font-style: italic;
}

.blog-heading h1 {
    margin-top: 70px;
    font-size: 37px;
}

.blog-heading .paragraph1 {
    margin-top: 40px;
    text-align: center;
    font-size: 20px;
    color: grey;
}

.blog-heading .paragraph2 {
    margin-top: 7px;
    font-size: 20px;
    margin-right: 40px;
    color: grey;
}

.blog-right-side {
    margin-top: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 95%;
}

.blog-right-side .image {
    width: 80%;
    height: 350px;
    box-shadow: 0px 0px 50px 0px rgb(0 0 0/25%);

    @media (max-width: 1024px) {
        width: 80%;
        height: 350px;
    }
}

.searchbar {
    width: 90%;
    background-color: #90c7ea;
    border-radius: 3px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px auto;
    margin-top: 40px;
}

.searchbar input {
    border: none;
    outline: none;
    background: none;
}

.glass-icon:hover {
    cursor: pointer;
    color: blue;
}

.blog-parent-cards {
    cursor: pointer;
    margin-top: 100px;
    width: 100%;
    background-color: #F7F7F7;
    height: 470px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-parent-cards .blog-card {
    width: 55%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 90%;
    }
}

.blog-parent-cards .card-body {
    padding: 20px;
}

.blog-parent-cards .card-title,
.blog-parent-cards .card-text,
.blog-parent-cards .hashtag,
.blog-parent-cards .text-muted {
    font-family: 'Your-Modern-Font', sans-serif;
    font-weight: lighter;
}
.blog-parent-cards .blog-card:hover {
    box-shadow: 0 8px 16px #90c7ea;
}
.blog-parent-cards .card-title,
.blog-parent-cards .card-text,
.blog-parent-cards .hashtag,
.blog-parent-cards .text-muted {
  margin-bottom: 8px;
}
.blog-parent-cards .card-title {
    font-family: 'Your-Title-Font', sans-serif; 
    @media (max-width:2600px) {
        font-size: 30px;
        
    }
    @media (max-width:768px) {
        font-size: 24px;
        
    }
    @media (max-width:1300px) {
        font-size: 24px;
        
    }
}
.blog-parent-cards .card-text,
.blog-parent-cards .hashtag,
.blog-parent-cards .text-muted {
  font-family: 'Your-Text-Font', sans-serif; 
 
  @media (max-width:2600px) {
    font-size: 24px;
    
}
@media (max-width:768px) {
    font-size: 16px;
    
  }
  @media (max-width:1300px) {
    font-size: 16px;
    
}
}

.blog-parent-cards + .blog-parent-cards {
  margin-top: 80px; 
}
.blog-parent-cards .card-img-top {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.blog-parent-cards .card-body {
    text-align: left;
}
.blog-parent-cards .card-body .hashtag, .text-muted {
    font-style: italic;
    color: grey;
    font-size: 12px;
    
}

.bottom-container {
    background: linear-gradient(to right, #90c7ea, transparent);
    padding: 40px 50px 20px 50px;
    margin-top: auto;
}

.bottom-main-cont {
    display: flex;
    gap: 4%;
}

.bottom-image {
    width: 50%;
    height: 400px;

    @media (max-width: 768px) {
        display: none;
    }
}

.bottom-image img {
    width: 500px;
    height: 400px;
    object-fit: cover;

    @media (max-width: 1024px) {
        width: 400px;
        height: 300px;
    }

}

.bottom-content-cont {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.bottom-header-cont {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 60px;

    @media (max-width: 1024px) {
        margin-left: 0px;
    }
}

.bottom-header-cont h1 {
    text-align: left;
    font-size: 32px;
}

.bottom-header-cont span {
    font-size: 16px;
}

.blog-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-left: 60px;

    @media (max-width: 1024px) {
        margin-left: 0px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.email-cont {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.bottom-form-label {
    font-size: 16px;
    text-align: left;
}

.bottom-input-field {
    width: 100%;
    border-radius: 5px;
}

.bottom-submit-btn {
    padding: 10px 30px;
    background-color: #5785ad;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}