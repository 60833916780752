.pop-main-cont{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    background-color: black;
}
.pop-iner-cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pop-card-cont{
    min-height: 25%;
    min-width: 20%;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 5px 10px 0 #2F4AA8;
    opacity: 999;
    position: relative;
}

.settings-close-btn {
    position: absolute;
    top: 30px;
    right: 20px;
    background: transparent;
    padding: 5px;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;

}
.pop-heading h1{
    text-align: center;
    color: #154360;
    font-weight: bold;
}
.add-input input{
    border-radius: 8px;
    width: 77%;
    background-color: #EFEFEF;
    padding: 15px 20px;
    border: 2px solid transparent;
    font-size: 17px;
    transition-property: all;
    transition-duration: 300;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.add-input input:focus{
    outline: none;
    border: 2px solid rgba(67, 119, 201, 0.685);
}
.add-input input ::placeholder{
    color: rgb(243, 238, 238);
    
}
.add-input2 input{
    border-radius: 8px;
    width: 89%;
    background-color: #EFEFEF;
    padding: 15px 20px;
    border: 2px solid transparent;
    font-size: 17px;
    transition-property: all;
    transition-duration: 300;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.add-input2 input:focus{
    outline: none;
    border: 2px solid rgba(67, 119, 201, 0.685);
}
.add-input2 input ::placeholder{
    color: rgb(243, 238, 238);
}

.add-input2 select{
    border-radius: 8px;
    width: 89%;
    padding: 15px 20px;
    border: 2px solid transparent;
    font-size: 17px;
    transition-property: all;
    transition-duration: 300;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #EFEFEF;

}
.add-input2 select:focus{
    outline: none;
    border: 2px solid rgba(67, 119, 201, 0.685);
}
.add-input2 select ::placeholder{
    color: rgb(243, 238, 238);
}

.first-name-last-name-cont{
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
}
.email-roleAssigned-cont{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
    
}
.add-input{
    width: 50%;
}
.add-input2{
    width: 100%;
}
.add-pop-btn{
    margin: auto;
    padding: 10px 30px;
    background-color: #32A3E9;
    border: none;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
}
.remove-pop-main-cont{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
    padding-inline: 30px;
}
.remove-pop-main-cont h3{
    font-size: 23px;
    color: rgb(156, 146, 146);
}
.remove-pop-main-cont h3 span{
    color: rgb(104, 97, 97);
}
.delete-input input{
    border-radius: 8px;
    width: 90%;
    padding: 15px 20px;
    border: 2px solid transparent;
    font-size: 17px;
    transition-property: all;
    transition-duration: 300;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.delete-input input:focus{
    outline: none;
    border: 2px solid rgba(67, 119, 201, 0.685);
}
.delete-input input ::placeholder{
    color: rgb(243, 238, 238);
    
}
.remove-pop-btn{
    margin: auto;
    padding: 10px 20px;
    background-color: red;
    border: none;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
@media screen and (max-width: 560px) {
    .remove-pop-main-cont h3{
        font-size: 20px;
        text-align: center;
    }
}
@media screen and (max-width: 490px) {
    .remove-pop-main-cont h3{
        font-size: 20px;
        text-align: center;
    }
    .delete-input input{
        width: 86%;
    }
    .add-input2 input{
        width: 86%;
    }
    .add-input input{
        width: 70%;
    }
}

/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  