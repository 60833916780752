.main-docs-section {
  margin-top: 0%;
  display: flex;
  align-items: start;
  width: 100%;
  height: 100vh;
}
.left-side-panel {
  width: 18%;
  margin-top: 1%;
  height: 100%;

  @media (min-width: 768px) {
    width: 22%;
  }
  @media (min-width: 900px) {
    width: 25%;
  }
  @media (min-width: 2000px) {
    width: 15%;
  }
  @media (min-width: 2500px) {
    width: 17%;
  }
}
.left-side-panel-desktop{
  @media (max-width:767px) {
    display: none;
  }
}

.left-side-panel-mobile{
  background-color: hsl(205, 62%, 89%);;
  position: absolute;
  width: 70%;
  height: auto;
  left: 0px;
  z-index: 10;
  text-align: left;

 
  @media (min-width:767px) {
    display: none;
  }
}
.left-side-panel-mobile .side-searchbar input{
  margin-bottom: 2%;
  margin-top: 6%;
  margin-left: 11%;
  width: 80%;
}
.left-side-panel-mobile .side-searchbar .glass-icon{
  margin-left: -12%;
  margin-bottom: -1%;
}
.left-side-panel .pages {
  color: gray;
  margin-top: -2%;
  margin-bottom: 4%;
  font-size: 14px;
  font-weight: bold;


  @media (min-width: 900px) {
    font-size: 18px;
  }
  @media (min-width: 1700px) {
    font-size: 20px;
  }
  @media (min-width: 2000px) {
    font-size: 20px;
  }
  @media (min-width: 2500px) {
    font-size: 20px;
  }
}
.left-side-panel .side-title {
  line-height: normal;
  margin-bottom: 5%;
  font-size: 16px;
  text-align: left;
  margin-left: 9%;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 900px) {
    font-size: 16px;
  } @media (min-width: 1700px) {
    font-size: 22px;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
}
.left-side-panel .side-title:hover {
  background-color: hsl(205, 100%, 75%);
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 6%;
}
.left-side-panel .side-title.selected {
  background-color: hsl(204, 75%, 84%);
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 6%;
}
.side-searchbar {
  margin-bottom: 11%;
}
.side-searchbar input {
  background-color: hsl(204, 75%, 84%);
  margin-top: -7%;
  margin-right: 3%;


  @media (min-width: 768px) {
    width: 85%;
  }
  @media (min-width: 1700px) {
    width: 75%;
  }
  @media (min-width: 2000px) {
    width: 80%;
  }
  @media (min-width: 2500px) {
    width: 80%;
  }
 
}
.side-searchbar .glass-icon {
  margin-bottom: -2%;
  margin-left: -13%;

  @media (max-width: 800px) {
    margin-left: -20%;
  }
  @media (min-width: 1200px) {
    margin-bottom: -1%;
  }
  @media (min-width: 1700px) {
    margin-bottom: -2%;
  }
  @media (min-width: 2000px) {
    margin-bottom: -1%;
  }
  @media (min-width: 2500px) {
    margin-bottom: -1%;
  }
 
}
.side-searchbar .glass-icon:hover {
  fill: blue;
}
.v-line {
  border-left: 1px solid black;
  height: 100vh;
  
}
.h-line {
  margin-left: 1%;
}
.middle-content {
  width: 80%;
  height: 100vh;
  text-align: left;
  margin-left: 0.3%;

  @media (min-width:200px) and (max-width:530px) {
    width: 100%;
    margin-left: -19%;
  }
  @media (min-width:540px) and (max-width:650px) {
    width: 100%;
    margin-left: -19%;
    
  }
  @media (min-width:532px) and (max-width: 767px) {
    width: 100%;
    margin-left: -13%;
  }
}
.middle-content .title {
  text-align: left;
  font-size: 35px;
  margin-left: 2%;
  margin-bottom: 1%;
  text-decoration: underline;

 
  @media (min-width: 1700px) {
    font-size: 38px;
  }
  @media (min-width: 2000px) {
    font-size: 38px;
  }
  @media (min-width: 2500px) {
    font-size: 40px;
  }
  
}
.middle-content .first-text {
  margin-left: 2%;
  font-size: 20px;
  color: #2f4aa8;
  margin-bottom: 2%;

  @media (min-width: 1700px) {
    font-size: 19px;
  }
  @media (min-width: 2000px) {
    font-size: 19px;
  }
  @media (min-width: 2500px) {
    font-size: 20px;
  }
}

.two-sections {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 80%;
  overflow: auto;
  scrollbar-width: thin;
}

.blur-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  z-index: 1;
  left: auto;
  top: 95px;
  text-align: center;
  padding-top: 200px;
}

.blur-layer span {
  font-size: 3rem;
  font-weight: bolder;
  color: rgb(9, 9, 62);
  cursor: pointer;
}

.two-sections .left-div .text {
  font-size: 16px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 1.7rem;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.7rem;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 1.7rem;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 1700px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (min-width: 2500px) {
    font-size: 23px;
    margin-top: 1%;
    line-height: 2rem;
  }
}
.two-sections .left-div {
  width: 77%;
  padding: 1rem;
  font-size: 20px;
  text-align: justify;

 
  @media (min-width: 200px) {
    width: 98%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 1700px) {
    width: 90%;
  }
  @media (min-width: 2000px) {
    width: 90%;
  }
  @media (min-width: 2500px) {
    width: 90%;
  }
}
.two-sections .left-div p {
  font-size: 18px;
  margin-bottom: 4%;
  text-align: justify;
}
.left-div .numbered{
  margin-bottom: 2%;
  padding-top: 2%;
  margin-left: 2%;
}
.two-sections .content h1 {
  color: blue;
  text-align: left;
  margin-bottom: 2%;

  @media (min-width: 1200px) {
    font-size: 30px;
  }
  @media (min-width: 2000px) {
    font-size: 34px;
  }

}
.two-sections .content .caption {
  font-size: 12px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 1.7rem;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 1.7rem;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (min-width: 2500px) {
    font-size: 23px;
    line-height: 2rem;
  }
}
.two-sections .content h2 {
  color: blue;
  text-align: left;
  font-size: 25px;
  margin-top: 3%;
  margin-bottom: 2%;

  @media (min-width: 1200px) {
    font-size: 30px;
  }
  @media (min-width: 2000px) {
    font-size: 34px;
  }

}
.two-sections .content .description {
  font-size: 12px;
  
  @media (min-width: 767px) {
    font-size: 12px;
    line-height: 1.7rem;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 1.7rem;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (min-width: 2500px) {
    font-size: 23px;
    line-height: 2rem;
  }
 
}
.two-sections .content img {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 3%;
  margin-top: 3%;
}
.two-sections .content .circle-bullet {
  list-style-type: disc;
  margin-bottom: 5px;
  position: relative;
  font-size: 15px;
  margin-left: 2%;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 1.7rem;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 1.7rem;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (min-width: 2500px) {
    font-size: 23px;
    line-height: 2rem;
  }
}
.two-sections .content .circle-subbullet {
  list-style-type: circle;
  margin-bottom: 5px;
  position: relative;
  margin-left: 4%;
  font-size: 15px;

  
  @media (min-width: 767px) {
    font-size: 16px;
    line-height: 1.7rem;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 1.7rem;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 1.7rem;
  }
  @media (min-width: 2000px) {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (min-width: 2500px) {
    font-size: 22px;
    line-height: 2rem;
  }
}
.two-sections .right-div {
  width: 23%;
  padding: 0.8rem;


  @media (min-width: 200px) and (max-width: 1100px) {
    display: none;
  }
  @media (min-width: 1700px) {
    width: 17%;
  }
  @media (min-width: 2000px) {
    width: 15%;
  }
  @media (min-width: 2500px) {
    width: 15%;
    margin-top: 0.9%;
  }
}
.two-sections .right-div p {
  margin-top: 23%;
  margin-left: 10%;
}
.two-sections .right-div svg {
  width: 10%;

  @media (min-width: 2000px) {
    margin-left: 3%;
  }
  @media (min-width: 2500px) {
    margin-left: 7%;
  }
}
.table-of-contents {
  margin-top: -26px;
  margin-left: 5%;
  font-size: 18px;


  @media (min-width: 1100px) {
    margin-top: -14%;
  }
  @media (min-width: 2000px) {
    margin-left: 20%;
    font-size: 20px;
  }
  @media (min-width: 2500px) {
    margin-left: 27%;
    margin-top: -34px;
  }
}
.table-of-contents h5 {
  font-size: 20px;
  margin-left: 10%;

  @media (min-width: 1100px) {
    font-size: 15px;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  @media (min-width: 1700px) {
    font-size: 20px;
    margin-bottom: 1%;
  }
  @media (min-width: 2000px) {
    font-size: 21.5px;
    margin-left: -2px;
    margin-bottom: 6%;
  }
  @media (min-width: 2500px) {
    font-size: 27px;
    margin-top: -3%;
    margin-bottom: 2%;
    margin-left: -8%;
  }
}
.table-of-contents ul {
  list-style-type: none;
  padding: 0;
  margin-left: 25%;
}
.table-of-contents ul li {
  margin-bottom: 8px;
}

.table-of-contents ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;

  
  @media (min-width: 1100px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
  }
  @media (min-width: 2000px){
    font-size: 20px;
  }
  @media (min-width: 2500px) {
    font-size: 20px;
    margin-left: -17%;
  }
 
}
.table-of-contents ul li ul {
  margin-top: 8px;
  margin-left: 2px;
}
.table-of-contents ul li a:hover {
  font-weight: bold;
  text-decoration: underline;
}
.pagination {
  display: flex;
  justify-content: space-between;
  width: 77%;
  margin-top: 1%;
  
  @media (min-width: 200px) {
    width: 98%;
  }
  @media (min-width: 1700px) {
    width: 84%;
  }
  @media (min-width: 2000px) {
    width: 86%;
  }
  @media (min-width: 2500px) {
    width: 85%;
  }
}
.pagination .previous-btn,
.pagination .next-btn {
  display: flex;
  align-items: center;
  background-color: none;
  border: none;
  border-radius: 7%;
  min-width: 0;
  padding: 1%;
  cursor: pointer;
}
.pagination .previous-btn svg {
  margin-right: 10px;
}
.pagination .next-btn svg {
  margin-left: 10px;
}
.left-panel-toggle-btn{
  overflow: hidden;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  margin-bottom: 2px;
  display: flex;


  @media (min-width: 767px) {
    display: none;
  }

}
.left-panel-toggle-btn{
  margin-top: -12%;
  overflow: hidden;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  margin-bottom: 2px;
  display: flex;
  font-weight: bold;
  color: gray;

  @media (min-width:200px) and (max-width:530px) {
    margin-top: -17%;
    
  }
  @media (min-width: 767px) {
    display: none;
  }

}