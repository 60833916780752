#contact-box {
    background-color: #246086;
    height: auto;
    padding: 6%;
    border-radius: 20px;
    text-align: justify;
}

.contact-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.forgotPassword-overlay{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
}

.contact-modal-content {
    background-color: white;
    padding: 20px;
    position: absolute;
    width: 450px;
    gap: 8px;
    font-size: 15px;
    border-radius: 8px;
    @media (max-width: 500px) {
        width: 90%;
    }

    @media (min-width: 1710px) {
        width: 600px;
    }
}

.heading {
    font-weight: bold;
    color: #2F4AA8;
    font-size: larger;
}

.forgotPassword-content {
    color: #000000;
    font-size: 20px;
}

.contact-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.forgotPassword-close-icon {
    cursor: pointer;
    font-size: 30px;
    color: rgb(71, 66, 66);
    position: absolute;
    right: 0px;
    top: 0px;
}

#inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    @media (min-width: 500px) {
        flex-direction: row;
    }
}

#inputs input {
    width: 100%;
    font-size: 12px;

    @media (min-width: 500px) {
        width: 50%;
        font-size: 14px;
    }

    @media (min-width: 1710px) {
        font-size: 22px;
    }
}

input {
    border: none;
    border-radius: 10px;
    text-align: start;
    padding: 10px 20px;

}

.email input {
    width: 100%;
    margin-top: 20px;
    font-size: 12px;

    @media (min-width: 500px) {
        font-size: 14px;
    }

    @media (min-width: 1710px) {
        font-size: 22px;
        margin-top: 5%;
    }
}

.radio-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    @media (min-width: 500px) {
        flex-direction: row;
    }
}

.radio-inputs div {
    width: 50%;

    @media (min-width: 1710px) {
        font-size: 22px;
    }
}

.radio-inputs div input {
    margin-right: 14px;
    width: 16px;
    height: 16px;
}

.selector select {
    width: 100%;
    margin-top: 20px;
    border: none;
    font-size: 12px;
    border-radius: 10px;
    padding: 10px 20px;

    @media (min-width: 500px) {
        font-size: 14px;
    }

    @media (min-width: 1710px) {
        font-size: 22px;
        margin-top: 5%;
    }
}

.text-box textarea {
    width: 100%;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
    padding: 10px;
    height: 100px;

    @media (min-width: 500px) {
        height: 120px;
    }

    @media (min-width: 1710px) {
        font-size: 22px;
        margin-top: 5%;
    }
}

.submit{
    display: flex;
    justify-content: center;
}
.submit input {
    background-color: whitesmoke;
    color: #2F4AA8;
    margin-top: 20px;
    padding: 10px 50px;
    text-align: center;
    border-radius: 40px;
    font-size: 14px;

    @media (min-width: 1710px) {
        margin-top: 5%;
        font-size: 22px;
    }
}

.check-out p {
    font-weight: bold;
    font-size: 14px;
    margin-top: 7%;
    font-style: italic;
}