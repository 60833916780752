.main-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 15px;
    box-sizing: border-box;
    gap: 20px;   
    background-color: #EFEFEF;
    min-height: 100vh;
}

.login-header-container{
    width: 400px;
    text-align: center;
    margin-left: -30px;
    margin-top: 70px;
    font-size: 30px;
    font-weight: bold;
    color: #154360;

    @media (max-width: 500px){
        margin-left: 0px;
        font-size: 30px;
        text-align: center;
    }
}
.login-container .header-container p{
    color: #154360;
}

.login-container {
    min-width: 500px;
    min-height: 600px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    gap: 20px;
    box-shadow: 0 5px 10px 0 #2F4AA8;
    margin-bottom: 100px;

    @media (max-width: 600px){
        min-width: 100%;
        width: 100%;
    }
}

.extra-container{
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
    padding-top: 15px;
    gap: 20px;   
    box-sizing: border-box;
    margin-bottom: 20px;
}

.header-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

.header-container span{
    text-align: center;
    color: #154360;
    font-size: 28px;
    font-weight: bold;
}

.header-container p{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.form{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    
}

.sign-up-form{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    margin-bottom: 30px;
}

.input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    box-sizing: border-box;
}
.input-container input:focus{
    outline: none;
    border: 2px solid #32A3E9;
}

.btn-links-contianer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    padding-block: 10px;
}

.btn-links-contianer .link{
    color: #154360;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    text-align: left;
}

.btn-links-contianer .not-account{
    text-align: left;
}

.btn-links-contianer .forgot-link{
    padding-left:  15px;
}

.btn-links-contianer .forgot-link span{
    cursor: pointer;
}

.link-signup{
    text-decoration: underline;
    color: #154360;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.login-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-btn-container button{
    padding-block: 10px;
    padding-inline: 50px;
    border-radius: 20px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: #32A3E9;
    cursor: pointer;
}

.login-full-btn-container button{
    width: 100%;
}

.login-rounded-btn-container button{
    border-radius: 8px;
}

.square-box{
    width: 100%;
    height: 80px;
    background-color: #D9D9D9;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 600;
    gap: 5px;
    color: #154360;
}

.square-box span{
    text-align: left;
}

.radio-main-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.radio-container span{
    font-size: 14px;
    font-weight: 600;
}

.business-option-container{
    display: flex;
    gap: 40px;
    width: 500px;
    height: 300px;

    @media (max-width: 600px){
        width: 100%;
        gap: 20px;
    }

    @media (max-width: 500px){
        width: 100%;
        height: auto;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-bottom: 30px;
    }
}

.option-container{
    width: 50%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    @media (max-width: 500px){
        width: 250px;
        height: 300px;
        justify-content: center;
    }
}

.option-container ul{
    list-style-type: circle;
    font-size: medium;
}

/* ------------------------------------------------------------------------------------------- */

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
}
  

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #F4F4F4;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .flip-card-front .icon-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
}
  
  .flip-card-back {
    background-color: #F4F4F4;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-block: 20px;
    text-align: start;
  }


/* ------------------------------------------------------------------------------------------- */

.group{ 
    position:relative;
}

.group input{
    width: 100%;
    padding-block: 16px;
    padding-inline: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 14px;
    box-sizing: border-box;
    background-color: #F4F4F4;
}

label{
    color:#999; 
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:20px;
    top:12px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
}
  
input:focus ~ label {
    top:0px;
    font-size:12px;
    color:#154360;
}

.show-password{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.show-password svg{
    font-size: 20px;
    color: #999;
    width: 20px;
    height: 20px;
}

.forgotPassword-container{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}