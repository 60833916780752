/* Research */
.consistent-margin {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 100px;
    margin-bottom: 10px;
    outline: 1px solid transparent;
    padding-bottom: 55px;

    @media (max-width: 768px) {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.title-research {
    text-align: center;
    font-size: 22px;
    margin-bottom: 2%;
}

.research-box {
    background-color: #f5f5f5;
    margin-top: 7%;
    height: auto;
    padding: 10px;
    border-radius: 3px;
}

.research-box p {
    text-align: start;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.72px;
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
}

#research {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-research-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
    width: 80%;

    @media (max-width: 768px) {
        width: 90%;
    }
}

.researchPaper {
    background: linear-gradient(to right, #6f9fbc, #bcd2dc);
    padding: 10px;
    border-radius: 3px;
    text-align: justify;
    word-wrap: break-word;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(177, 175, 175, 0.19);
    font-size: 16px;
    width: auto;
    height: auto;
    letter-spacing: 0.72px;
    text-align: justify;
    line-height: 1.2;
    transition: transform 0.5s;


    @media (min-width: 1537px) {
        font-size: 0.8em;
    }

    @media (min-width:2000px) {
        font-size: 1em;

    }
}
.researchPaper:hover {
    transform: scale(1.1);
}
.publisher-arrow {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: 0.72px;
    text-align: justify;
    line-height: 1.2;
    font-style: italic;
    color: whitesmoke;
    font-weight: bold;

    @media (min-width: 1537px) {
        font-size: 0.8em;
    }

    @media (min-width:2000px) {
        font-size: 1em;

    }
}

.publisher {
    margin-top: 130px;
}

.arrow-up svg {
    height: 30px;
    margin-top: 145px;
}

/* /Research */