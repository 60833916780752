/* --------------//////////Variables/////////// ------------ */

:root {
  /* text colors */
  --text-gray: #808291;
  --text-white: #f8f9fa;
  --text-light: #f6f6f6;
  --text-dark: #1A3260;
  --text-dark2: #1A3760DA;
  --text-red: #f98096;
  --box-shadow: 0px 0px 50px 0px rgb(0 0 0/10%);
  --bg-blue: rgb(216, 237, 248);
  --bg-red: rgb(248, 207, 211);
  --bg-yellow: rgb(241, 241, 219);
  --bg-purple: rgb(247, 224, 248);
  --bg-green: rgb(216, 243, 222);


  /* font family */
  --font-rubik: 'Roboto', sans-serif;

  /* gradient colors */
  --bg-gradient: linear-gradient(to right, #ff512f, #dd2476);
  --bg-gradient-indigo: linear-gradient(to right, #8e2de2, #631fea);
  --bg-gradient-peach: linear-gradient(to right, #f0c5b0, #fff7f3);


}


*>* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.consistent-margin{
  margin: 20px;
  outline: 1px solid transparent;

}
a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

/* -------------- //////////Variables//////////// ------------ */


/* -----------------/////////////global classes//////////----------- */
.text-dark {
  color: var(--text-dark);
}

.text-white {
  color: var(--text-white);
}

.text-light {
  color: var(--text-light);
}

.text-gray {
  color: var(--text-gray);
}

.text-red {
  color: var(--text-red);
}

.bg-gradient {
  background: var(--bg-gradient);
}

.bg-gradient-indigo {
  background: var(--bg-gradient-indigo);
}

.bg-gradient-peach {
  background: var(--bg-gradient-peach);
}

.bg-gradient-red {
  background: var(--bg-red);
}

.bg-gradient-blue {
  background: var(--bg-blue);
}

.bg-gradient-yellow {
  background: var(--bg-yellow);
}

.bg-gradient-purple {
  background: var(--bg-purple);
}

.bg-gradient-green {
  background: var(--bg-green);
}

.font-poppins {
  font-family: var(--font-rubik);
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 1em;
  line-height: 1.5em;
}

.text-md {
  font-size: 1.5em;
}

.tex-lg {
  font-size: 2.2em;
  line-height: 3rem;
}

.text-xl {
  font-size: 3em;
}

.text-2xl {
  font-size: 5em;
}

.font-bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.jusify-center {
  justify-content: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.container {
  width: 100%;
}

.container-fluid {
  width: 98%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-10 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.px-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-10 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.link {
  color: var(--text-dark2);
  font-weight: bold;
}

.link:hover {
  color: var(--text-red);
}

.btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.btn-primary {
  padding: .8rem 2rem;
  border-radius: 50px;
  font-family: var(--font-rubik);
  font-weight: bold;
}

.btn-shadow {
  box-shadow: var(--box-shadow);
}

.btn-primary:hover {
  background: var(--bg-gradient-indigo);
}

.bg-cover {
  background: var(--bg-cover);
  object-fit: fill;
}

.d-inline {
  display: inline-block;
}

.d-block {
  display: block;
}

.grid {
  display: grid;
}

.cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.btn-shadow {
  box-shadow: var(--box-shadow);
}

body {
  width: 100%;
  background-color: white;
  color: #000000;
  font-family: var(--font-rubik);
  font-size: 22px;
}

/* -------------------////////// global classes//////////----------- */


#container {
  background-color: white;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  color: black;
  min-height: 100vh;
}

#mainContent {
  color: rgb(232, 118, 118);
  padding: 40px;
}

#section {
  width: 100%;
  overflow: auto;
  padding: 40px;
  color: rgb(17, 17, 17);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  background-color: #8e2de2;
}

/* TextSecion-Area */
.textsection-area {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px 20px 10px;
}

.textsection-area .flex {
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-right: 40px;

  @media (min-width: 1135px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.textsection-area .flex .item-1 {
  width: 20%;

  @media (max-width: 1135px) {
    width: 100%;
  }
}

.textsection-area .flex .item-2 {
  border: 1px solid rgb(1, 47, 85);
  height: 350px;
  width: 50%;
  align-self: center;

  @media (max-width: 1135px) {
    width: 100%;
  }
}

.textsection-area .flex .item-2 .codecont {
  width: 100%;
  height: 100%;
}

.textsection-area .flex .item-3 {
  width: 30%;
  overflow: scroll;
  border: 1px solid rgb(1, 47, 85);
  min-height: 350px;

  @media (max-width: 1135px) {
    width: 100%;
  }
}

/* //TextSection-Area */

#codeSection {
  overflow: scroll;
  padding: 20px;
  width: 100%;
  height: 100%;
  align-self: center;
  font-size: 26px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

#apiResponseSection {
  padding: 20px;
  border-radius: 5px;
  min-height: 300px;
  max-width: 700px;
  max-height: fit-content;
  align-self: center;
}

h2 {
  text-align: center;
  font-family: var(--font-rubik);
  font-size: medium;
}

h1 {
  text-align: center;
  font-family: var(--font-rubik);
}

.header-grid-container {
  background-color: #FFF;
  display: grid;
  padding-top: 135px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: center;
  column-gap: 10px;
  row-gap: 100px;
  font-family: var(--font-rubik);

  @media (min-width: 768px) {
    grid-template-rows: 1fr 1fr;
  }

  @media (min-width: 768px) {
    height: 700px;
  }
}


/* ALL THE BUTTON PROPERTIES */

.btn {
  display: inline-block;
  background: #32a3e9;
  color: white;
  border: none;
  padding: 15px 25px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

/* Miscellaneous: */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* ALL NAVBAR PROPERTIES */

#nav {
  background-color: #154360;
  text-align: center;
  padding: 5px;
  margin-bottom: 20px;
  transition: all 0.9s ease;
  display: none;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 12px 6px -2px,
    rgba(0, 0, 0, 0.25) 0px 30px 60px -3px;

  @media (min-width: 768px) {
    display: flex;
    position: relative;
    z-index: 10;
  }
}

.btn-login-nav {
  color: white;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 15px;
  border: 2px solid #8CC63F;
  background: #8CC63F;
  font-size: 18px;
}
#nav .login-link:hover{
  color: #8CC63F;
  border-bottom: 2px solid #8CC63F;
}
.navMain-con {
  display: flex;
  align-items: center;
  gap: 8%;
}

.items-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.items-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#mobile-nav {
  overflow: hidden;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  margin-bottom: 2px;
  display: flex;


  @media (min-width: 768px) {
    display: none;
  }
}

.toggle-icon {
  justify-content: flex-end;
}


.icon-size {
  width: 50px;
  height: 50px;
}

.nav-modal {
  background-color: #154360;
  position: absolute;
  width: 100%;
  height: 510px;
  left: 0px;
  z-index: 10;

  @media (min-width: 768px) {
    display: none;
  }
}

.nav-modalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 10px;
  width: 100%;
  margin-top: 60px;
  padding: 0px 20px;
}

.navbar a {
  font-size: 18px;
  color: black;
  text-align: center;
  padding: 14px 16px 10px 10px;
  text-decoration: none;
}
.dropdown {
  position: relative;
}

.dropdown .dropdown-menu{
  position: absolute;
  background-color: white;
  padding-inline: 10px;
  padding-block: 10px;
  left: -100px;
  min-width: 160px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  font-weight: bold;
}
@media (max-width:777px) {
  .dropdown .dropdown-menu{
   right: -130px;
   left: 30px;
  }
}

.dropdown .dropdown-menu span {
  cursor: pointer;
  color: black;
  
}
.dropdown .dropdown-menu span:hover{
  text-decoration: underline;
  text-decoration-color: #32A3E9;
  text-decoration-thickness: 3px;
  text-decoration-style: solid;
}

.dropdown .dropbtn {
  font-size: 18px;
  border: none;
  outline: none;
  color: whitesmoke;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .dropbtn {
  border-bottom: 2px solid whitesmoke;}

.dropbtnlink {
  color: whitesmoke;
}

.dropbtnlink:hover {
  border-bottom: 2px solid whitesmoke;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #154360;
  min-width: 160px;
  box-shadow: var(--box-shadow);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: whitesmoke;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-content a:hover {
  border-bottom: 2px solid whitesmoke;
}

.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 16px;

}


/* Cipher heading */
.cipher {
  margin-top: -10%;

  @media (min-width:2000px) {
    margin-right: 7%;
  }

  @media (max-width:768px) {
    margin-right: 8%;
  }
}

.cipher .two {
  margin-left: 20%;
  margin-top: 0%;
  @media (min-width:2000px) {
    margin-right: 7%;
  }
}
.cipher .paragraph {
  margin-left: 19%;
  margin-top: 25px;
  text-align: left;
  font-size: 18px;
  line-height: 140%;
  color: #2F4AA8;
}

.cipher2 {
  margin-top: 20px;
  margin-left: 17%;
  gap: 50px;

  @media (max-width:768px){
    margin-left: 0%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.cipher2 .learn {
  margin-top: 20px;
  font-size: 15px;
}

.cipher2 svg {
  margin-left: 15px;
}

.video-part {
  margin-top: -45px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 95%;
}

.video {
  width: 80%;
  height: 350px;
  box-shadow: 0px 0px 50px 0px rgb(0 0 0/25%);

  @media (max-width: 1024px) {
    width: 80%;
    height: 350px;
  }

}

/*////// Cipher heading */

.agency-area {
  background-color: #F7F7F7;
  padding-top: 40px;
}

.agency-area .txt {
  font-size: 15px;
  color: #2F4AA8;
  text-transform: uppercase;
  letter-spacing: 0.42px;
}

.agency-area .area-title {
  line-height: normal;
  letter-spacing: 0.42px;
}

.agency-area .grid {
  column-gap: 1em;
  row-gap: 1em;
}
.leftIcon{
  @media screen and (max-width: 1024px){
    display: flex;
    justify-content: left;
    padding-left: 14px;
  }
}

.card {
  border: none;
  width: 100%;
  height: 230px;
  box-shadow: 0px 0px 50px 10px rgb(0 0 0/15%);
  background-color: #F7F7F7;
  padding-right: 10px;
  padding-bottom: 10px;
  transition: transform 0.5s;
}

.card:hover {
  transform: scale(1.1);
}

.agency-area .grid-item .card svg {
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 23px;
  margin-right: 140px;

  @media (min-width: 1600px) {
    margin-left: -100px;
  }

}

.data {
  margin-left: 18px;
  font-size: 14px;
  color: black;
  text-align: left;
  line-height: 140%;
}

.data1 {
  margin-left: 18px;
  font-size: 13px;
  color: black;
  text-align: left;
  line-height: 140%;
}

/* main content area */
.main-content-area {
  padding-top: 40px;
  margin-top: 50px;
}

.main-content-area .txt {
  font-size: 15px;
  color: #2F4AA8;
  text-transform: uppercase;
  letter-spacing: 0.42px;
}

.main-content-area .area-title {
  line-height: normal;
  letter-spacing: 0.42px;
}

.main-content-area .grid img {
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* /maincontent */
/* ----------/Nutshell and model -------------- */

.nutshell-container{
  width: 100%;
  background-color:#F7F7F7;
  padding: 20px 80px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding: 10px 10px;
  }
}
.nutshell-container h1{
  color: #154360;
}

.nutshell-container p, .nutshell-container span{
  font-size: 0.8em;
  margin-top: 3%;

  @media (min-width: 1537px){
    font-size: 0.9em;
}
  @media (min-width:2000px) {
    font-size: 1.2em;
    
  }

  @media (max-width:760px) {
    padding: 2%;
  }
}
.end-para{
  font-size: 0.8em;
  margin-top: 3%;
  text-align: justify;
}

.nutshell-link-bold{
  font-weight: bold;
}

.nutshell-link{
  cursor: pointer;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  @media (max-width: 640px) {
    align-items: flex-end;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  background-color: white;
  border-radius: 8px; 
  width: 60%;
  height: 70%;
  display: flex;
  flex-direction: column;
  padding: 6px;
  gap: 8px;
  padding: 20px 0px;
  font-size: 15px;

  @media (min-width: 1024px) {
    width: 85%;
    height: 35%; /* Adjusted height for medium screens */
    margin-bottom: 50%;
  }
  @media (max-width: 640px) {
    width: 100%;
    height: 40%; /* Adjusted height for small screens */
    margin-bottom: 160%;
  }
  @media (min-width: 1537px) {
    width: 800px;
    /* height: 950px; */
    height: 700px; /* Adjusted height for large screens */
    margin-bottom: -5%;
}
}
.model-close-cont{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding: 0px 60px;

  @media (max-width: 640px) {
    padding: 0px 20px; 
  }
}

.model-text-cont{
  overflow-y: auto;
  padding: 0px 60px;
  text-align: justify;

  @media (max-width: 640px) {
    padding: 0px 20px; 
  }
}
.model-text-cont p{
  padding-bottom: 20px;
  line-height: 1.6;
  font-size: 18px;
}


/* Responsive Styles for Small Screens (up to 768px) */
@media (max-width: 768px) {
  .container {
    max-width: 768px;
  }

  .cipher {
    margin-top: -5%;
  }


}

/* Responsive Styles for Medium Screens (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .container {
    max-width: 769px;
  }

  .md-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }


}

/* Responsive Styles for Large Screens (1025px to 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
  .container {
    max-width: 1025px;
  }

  .lg-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg-cols-3-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }




}

/* Responsive Styles for Extra-Large Screens (greater than 1440px) */
@media (min-width: 1441px) {
  .container {
    max-width: 1441px;
  }

  .lg-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg-cols-3-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

/* ----------Footer-------------- */
#footer {
  border-top: 2px solid var(--text-light);
  background-color: #154360;
  width: 100%;
}

#footer .flex {
  column-gap: 1em;
  padding: 1em 0;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    
  }
}
#footer .flex div a {
  color: whitesmoke;
  text-decoration: none;
}

#footer .flex div a:hover {
  color: whitesmoke; 
  text-decoration: underline;
}
#footer .copyrights p {
  py-2: 1em;
}

#footer div .contact{
  cursor: pointer;
}
#footer div .contact:hover{
  color: whitesmoke; 
  text-decoration: underline;
}

#footer .copyrights {
  margin-top: -0.9em; 
  font-size: medium;
}

#footer .flex div {
  position: relative;
  color: whitesmoke;
  text-decoration: none;
}
@media (min-width:768px) {
  #footer .flex div:not(:last-child)::after {
    content: "|";
    margin-left: 0.5em;
  }
  
}
/* ----------/Footer -------------- */


