.pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 600px;
  }
  
  .pricing h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1A3260;
    font-weight: bolder;
  }
