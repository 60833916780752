#back-Button{
    width: 100px;
    height: 40px;
    padding: 10px;
    background-color: #32a3e9;
    margin-top: 10px;
    margin-bottom: -47px;
    margin-left: -87%;


    @media (min-width:1000px) {
        margin-left: -89%;
    }
    @media (min-width:1100px) {
        margin-left: -90%;
    }
    @media (min-width:700px) {
        margin-left: -78%;
    }
    @media (min-width:1200px) {
        margin-left: -89%;
    }
    @media (min-width: 1500px) {
        margin-left: -92%;        
    }
    @media (min-width: 2000px) {
        margin-left: -94%;        
    }
    @media (min-width:2300px) {
        margin-left: -94%;
    }
}
#back-Button p{
    color: white;
    display: flex;
    align-items: flex-start;
    margin-left: 24px;
    margin-top: -22px;
}
#back-Button .buttonText{
    color: white;
}
#back-Button svg{
    width: 20px;
    align-items: flex-start;
    display: flex;
}

#back-Button.blue-btn {
    background-color: #32a3e9;

    @media (min-width:2500px) {
        width: 120px;
        height: 50px;
        
    }
    @media (min-width:2000px) {
        width: 120px;
        height: 50px;
        
    }
    @media (min-width:200px) {
        margin-bottom: 10px; 
    }
    @media (max-width:700px) {
        margin-bottom: 10px; 
    }
}
#back-Button.research-btn .buttonText {
    font-size: 14px; 
    margin-left: 30px; 
    margin-top: -20px;

    @media (min-width:2000px) {
        font-size: 18px;
    }

  }