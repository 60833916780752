.privacy-container{
    padding-inline: 50px;
    padding-top: 50px;
    text-align: left;
}

.privacy-header {
    border-bottom: 1px solid rgb(57, 54, 54);
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
}

.privacy-content-container {
    padding-inline: 50px;
    padding-top: 25px;
}

.p-container{
    padding-bottom: 25px;
}

.p-heading {
    font-size: 1.6rem;
    font-weight: bold;
    color: #154360;
}

.p-content {
    margin-top: 10px;
    font-size: 18px;
}